/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 100%;
}
.section--footer .desk {
  max-width: 94%;
}
.mnavibg {
  display: none;
}
.spacer {
  margin-left: 3%;
  width: 62.8125%;
}
.bluecontent {
  float: right;
  width: 31.25%;
}
.hotline {
  margin-top: 60px;
}
.togglenavigation {
  right: 0;
  top: 15px;
  float: right;
  position: fixed;
  margin-right: 10px;
}
#mobile-navi {
  width: 31.25%;
  right: -31.25%;
}
#mobile-navi .navi.sub1 {
  margin-top: 60px;
}
@media only screen and (max-width: 1023px) {
  #find {
    width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .section--navigation .spacer {
    display: none;
  }
  .section--navigation .bluecontent {
    width: 94%;
    padding: 0 3%;
  }
  .section--navigation .service_offerte {
    width: 240px;
    margin: 2px 0;
  }
  .section--header {
    position: relative;
    z-index: 6;
  }
  .section--header .bluecontent {
    margin-bottom: -34px;
  }
  #find {
    margin-bottom: 2px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 104%;
  margin-left: -2%;
}
.main .cb-layout2 {
  margin-bottom: 60px;
}
.north {
  width: 104%;
  margin-left: -2%;
}
div.side {
  margin-bottom: 60px;
}
@media only screen and (max-width: 1023px) {
  div.side {
    width: 104%;
    margin-left: -2%;
  }
  div.side div.unit {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
.cb-layout3 div.main div.unit div.head h2,
.cb-layout3 div.main div.unit div.part {
  margin-left: 2%;
  margin-right: 2%;
}
.cb-layout3 div.main div.unit div.part {
  width: 96%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */